/* @import '../node_modules/prismjs/themes/prism-tomorrow';
@import './assets/scss/app.scss';
 */
.hesap-bilgileri-title {
  border: 1px solid #d8d6de;
  height: 100%;
  padding: 0.571rem 1rem;
  text-wrap: nowrap;
  background-color: #f7f7f7c2;
}

.select__menu {
  z-index: 99999 !important;
}
.fixed-right-bar {
  position: fixed;
  right: 1rem;
  height: 100%;
}

.vertical-divider {
  width: 1px;
  margin: 0 1rem;
  background-color: #d8d6de;
  padding: 0;
}
.loader {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #1111, $alpha: 0.1);
  display: grid;
  place-items: center;
}
.bg-tab-content {
  background-color: rgba(201, 215, 221, 0.2);
}
.odeme-alani {
  padding: 0.5rem 0.3rem;
}
.swal2-actions {
  gap: 1rem;
}
.doviz-ekrani {
  height: 100dvh;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  font-family: "Nunito", sans-serif;
  header {
    border-bottom: 1px solid rgba($color: #e3e1d9, $alpha: 1);
    height: 75px;
    display: flex;
    align-items: center;
    .header-logo {
      font-size: 24px;
      font-weight: 500;
      color: #387adf;
    }
  }
  .doviz-footer {
    height: 50px;
    background-color: #fff;
    border-top: 1px solid rgba($color: #e3e1d9, $alpha: 1);
    display: flex;
    align-items: center;
  }
  .main {
    flex: 1;
    width: 100%;
  }
}
.doviz-ekrani-list {
  width: 100%;
  .doviz-list-view {
    height: calc(100dvh - 250px);
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 2px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #000000;
      border: 2px solid transparent;
    }
  }
  .list-title {
    .list-title-col {
      padding-left: 0;
      padding-right: 0;
      &:last-child .list-title-item {
        border-right: 0;
      }
    }
    .list-title-item {
      font-size: 14px;
      font-weight: 400;
      background-color: #1b3c73;
      color: #fff;
      padding: 10px 5px;
      border-right: 1px solid #fff;
    }
  }
  .list-item {
    position: relative;
    border-bottom: 1px solid rgba($color: #222, $alpha: 0.3);
    .list-item-view {
      padding: 20px 5px;
      border-right: 1px solid rgba($color: #222, $alpha: 0.3);
      text-align: end;
    }
    .list-active-status {
      width: 5px;
      height: 90%;
      &.up {
        background-color: green;
      }
      &.down {
        background-color: red;
      }
    }
  }
}
.horizontal-menu
  .header-navbar.navbar-horizontal
  ul#main-menu-navigation
  > li
  svg {
  width: 12px !important;
  height: 12px !important;
}
.barcode-tag {
  border-radius: 0.357rem;
  &.gold-barcode {
    border: 1px solid #d8d6de;
  }
  .tag-input {
    border: none;
    padding: 0.571rem 1rem;
    border-radius: 0.357rem;
    width: 100%;
    &:focus {
      outline: none;
      border: none;
    }
  }
  .tags-area {
    padding: 0.571rem 1rem;
  }
  .tags-area-items {
    padding: 0.26rem 0.6rem;
    border-radius: 0.357rem;
    background-color: #7367f0;
    color: #fff;
  }
}
@media (max-width: 990px) {
  .doviz-ekrani-list {
    margin-bottom: 20px;
  }
  .doviz-list-view {
    height: auto !important;
  }

  .currency-type-button {
    padding: 5px, 10px, 5px, 10px;
    border-radius: 20px;
    color: rgba(0, 0, 0, 0.3);
    &.active {
      color: #fff;
      background-color: #1a3b71;
    }
  }
  .doviz-ekrani-list-mobile {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    .list-item-view-title {
      width: 80px !important;
    }
    .list-title {
      padding: 30px 0 20px 0;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
      overflow: auto;
      flex-wrap: nowrap;
      .up-arrow {
        display: none;
      }
      &.up {
        .price {
          color: #58bd7d;
        }
        .up-arrow {
          display: block;
        }
        .down-arrow {
          display: none;
        }
      }
      &.down {
        .price {
          color: #d33535;
        }
        .up-arrow {
          display: none;
        }
        .down-arrow {
          display: block;
        }
      }
    }
    .list-title-first {
      border-bottom-color: transparent;
    }
  }
}
@media (max-width: 990px) {
  .container {
    max-width: 100% !important;
  }
}

.sales-list-columns {
  min-width: 75px;
  width: 150;
}
.sales-list-columns-type {
  width: 50px;
}
.sales-list-columns-lg {
  width: 175px;
}
.list-page .pagination {
  margin-bottom: 0;
}

.form-error-input-border {
  border-color: #d33535 !important;
}
.form-error-input-label {
  color: #d33535 !important;
}
.sales-list-table .rdt_TableCell,
.sales-list-table .rdt_TableCol {
  padding: 0.32rem 0.75rem !important;
}
.sales-price-text {
  width: 200px;
}
.render-data-kasa {
  max-height: 130px;
  overflow-y: auto;
  overflow-x: hidden;
}
.render-data-kasa::-webkit-scrollbar {
  width: 5px;
}

.render-data-kasa::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.render-data-kasa::-webkit-scrollbar-thumb {
  background-color: #c1c4c5;
  border-radius: 10px;
}

.case-row {
  border-bottom: 1px solid #d5d5d5;
  padding: 1rem 0;
  &:first-child {
    border-top: 1px solid #d5d5d5;
  }
  &:last-child {
    margin-bottom: 1rem;
  }
}
